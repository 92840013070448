::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #5e5e5e;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #212121;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #23282c;
    text-align: left;
    overflow-x: hidden;
}


* {
    box-sizing: border-box;
}

.noselect {
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

a,
.link {
    color: #2196f3;

    &:hover {
        cursor: pointer;
        color: #1b80d3;
    }

    &:active {
        color: #378ed6;
    }

    &.red {
        color: #f44336;

        &:hover {
            color: #d1352a;
        }
    
        &:active {
            color: #f5665c;
        }
    }
}

.empty-msg {
    text-align: center;
    font-size: 22px;
    color: #a7a7a7;
    margin-top: 30px;

    &.medium {
        font-size: 18px;
    }
}

.loading {
    text-align: center;
    font-size: 22px;
    color: #a7a7a7;
    margin-top: 30px;

    &.medium {
        font-size: 18px;
    }
}

.frm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;

    h1 {
        width: 100%;
        font-size: 16px;
        border-bottom: solid 1px #dddddd;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            span {
                font-size: 13px;
                font-weight: normal;
                margin-left: 12px;
            }
        }
    }

    .filter-bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 38px;

        input, select {
            border: solid 1px #e0e0e0;
            background: #ffffff;
            height: 24px;
            padding: 0 6px;
            outline: none;
        }
    }

    .text {
        width: 100%;
    }

    .frm-input {
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
        margin: 6px 0;

        &.fullwidth {
            width: 100%;
        }

        &.checkbox {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;

            label {
                font-size: 14px !important;
            }
        }

        label {
            display: flex;
            font-size: 12px;
            color:  #969696;

            .act-link {
                margin-left: 6px;
            }
        }

        form {
            width: 100%;
            
            input, select, textarea {
                width: 100%;
            }
        } 

        input, select, textarea {
            background-color: #F4F8FB;
            border: solid 1px #E3E4E8;
            height: 32px;
            padding: 0 12px;
            border-radius: 4px;
            outline: none;

            &:focus {
                border-color: #32a4f0;
            }

            &.multiple {
                height: auto;
                padding: 0;
            }
        }

    }

    .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 18px;

        button {
            margin: 3px 0 3px 6px;
        }
        
    }
}

.button {

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }
}

button {
    border: none;
    background-color: #bdbdbd;
    color: #000000;
    font-size: 14px;
    border-radius: 4px;
    padding: 0 12px;
    height: 32px;
    margin: 3px 6px;
    min-width: 92px;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }

    &.blue {
        background-color: #2196f3;
        color: #ffffff;
    }

    &.red {
        background-color: #f44336;
        color: #ffffff;
    }

    &.green {
        background-color: #4caf50;
        color: #ffffff;
    }
}

.ctable {
    display: flex;
    flex-direction: column;
    width: 100%;

    .crow {
        display: flex;
        flex-direction: row;

        .ccell {
            padding: 6px;
        }

        &:hover {
            cursor: default;
            background-color: #e3f2fd !important;
        }
        
        &.selected:not(.head) {
            background-color: #32a4f0 !important;
            color: #ffffff !important;
        }

        &.head {
            background-color: #eeeeee;

            .ccell {
                font-weight: bold;
            }
        }

    }
}

.full-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000050;
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;

    .popup {
        background-color: #ffffff;
        border: solid 1px #bdbdbd;
    }
}

.font-red {
    color: #f44336;
}

.text-centred {
    text-align: center;
}

.select-list {
    background-color: #F4F8FB;
    border: solid 1px #E3E4E8;
    padding: 0;
    border-radius: 4px;
    min-height: 130px;
    overflow-y: scroll;

    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;

    .select-item {
        height: 26px;
        line-height: 26px;
        padding: 2px 12px;

        &:hover {
            cursor: default;
            background-color: #dadada;
        }

        &.selected {
            background-color: #32a4f0;
            color: #ffffff;
        }
    }
}